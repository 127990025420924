
          @import "src/styles/global";
        

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    height: 6rem;
    width: 6rem;
  }
}

.embedContainer {
  min-height: 300px;
}

.spinner {
    width: 100%;
    height: 100%;
    /*animation-delay: 1s;*/
    /*animation-duration: 4s;*/
    /*animation-name: winetime;*/
    /*animation-iteration-count: infinite;*/
}

@keyframes winetime {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0);
    }
}

